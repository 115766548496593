<template>
    <div>
                <!-- :style="{
                    borderBottom:
                        rowtitle == item.title
                            ? '3px solid #0037CC'
                            : '3px solid transparent'
                }" -->
        <a-anchor @click="tobox" :affix="true" @change="onChange">
            <a-anchor-link
                v-for="(item, index) in list"
                :key="index"
                :href="item.href"
                :title="item.title"
            >
            <transition name="slide-fade">
                <div v-show="rowtitle == item.title" class="box" :style="{
                        borderBottom:
                            rowtitle == item.title
                                ? '3px solid #0037CC'
                                : '3px solid transparent'
                    }"></div>
            </transition>
            </a-anchor-link>
        </a-anchor>
    </div>
</template>

<script>
export default {
    name:'anchor',
    props:{
        list:{
            type:Array,
        }
    },
    data(){
        return{
            rowtitle:'',
            
        }
    },
    created(){
        this.rowtitle = this.list[0].title
    },
    mounted(){
    },
    methods:{
        tobox(i, e) {
            i.preventDefault()
            console.log(i, e)
            this.rowtitle = e.title
        },
        onChange(link) {
            this.list.forEach(el=>{
                if(el.href == link){
                    this.rowtitle = el.title
                    
                }
            })
            // console.log('Anchor:OnChange', link,);
        }
    }
}
</script>

<style lang="scss" scoped>
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .7s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  opacity: 0;
}
.slide-fade-leave-to{
    transform: translateX(80px);
}

// 锚点样式修改
/deep/ .ant-affix{
    // top: 100px!important;
}
/deep/.ant-anchor-link-title{
        font-family: HarmonyOS Sans SC;
        color: #111A37;
        font-weight: bold;
        font-size: 16px;
        &:hover{
            color: #717b99;
        }
}
/deep/ .ant-anchor-wrapper {
    border: 1px solid #f0f2f5;
}
/deep/ .ant-anchor {
    width: 1280px;
    margin: 0 auto;
    height: 69px;
    display: flex;
    .ant-anchor-link {
        line-height: 69px;
        padding: 0 7px;
        margin-right: 60px;
    }
}
 /deep/ .ant-anchor-link-active{
     position: relative;
    //  border-bottom:3px solid #0037CC;
        //   transform: translateX(50%);
        //  transition: all .3s ease-in-out;
 }
 .box{
     position: relative;
     top: -10px;
 }
/deep/ .ant-anchor-ink-ball{
    visibility:hidden;
}
/deep/ .ant-anchor-ink::before{
    visibility:hidden;
}</style>
