<template>
    <div>
        <div class="pageposition">
            <div>
                <a-pagination
                    size="small"
                    :defaultPageSize="1"
                    :total="total"
                    @change="tablechange"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pagenation',
    props: {
        total: {
            type: Number
        }
    },
    watch: {
        total(e) {}
    },
    methods: {
        tablechange(page) {
            this.$emit('pagechange', page)
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-pagination-item {
    position: relative;
    background-color: transparent;
    border: none;
}
/deep/ .ant-pagination-item:before {
    content: '';
    display: block;
    width: 4px;
    height: 1px;
    position: absolute;
    top: 11px;
    left: -2px;
    background: #a9b3cc;
    opacity: 1;
}
/deep/ .ant-pagination-item-1:before {
    display: none;
}
/deep/ .ant-pagination-prev {
    .anticon {
        display: none;
    }
    visibility: none;
    background-image: url('../../assets/images/solution/left.png');
    background-size: 19px 5px;
    background-position-y: center;
    background-repeat: no-repeat;
}
/deep/ .ant-pagination-next {
    .anticon {
        display: none;
    }
    visibility: none;
    background-image: url('../../assets/images/solution/right.png');
    background-size: 19px 5px;
    background-position-y: center;
    background-repeat: no-repeat;
}
/deep/ .ant-pagination-item-active {
    width: 14px;
    height: 32px;
    font-size: 24px;
    font-family: HarmonyOS Sans SC;
    font-weight: 500;
    line-height: 36px;
    a {
        color: #111a37;
    }
}
.pageposition {
    margin-left: 40px;
    margin-top: -10px;
}
</style>
