<template>
<div>
    <div v-if="nowRoute == 'mediaCenter'" class="mediaCenter">
        <div class="media-head">
            <h3>媒体中心</h3>
            <h4>NEWS</h4>
        </div>
        <div class="maodian">
            <anchor :list="list"></anchor>
        </div>
        <span id="jiusinDynamic"></span>
        <!-- 酒星动态 -->
        <div class="media-maina">
            <div class="main-left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="main-center">
                <div class="center-top">
                    <div class="center-left">
                        <h3>酒星动态</h3>
                        <img
                            src="../assets/images/logo/组110@2x.png"
                            alt=""
                            class="img-circle"
                        />
                        <h4>jiusin dynamic</h4>
                    </div>
                </div>
                <!-- 酒星动态 -->
                <div class="center-bottom">
                    <div @click="toDetial">
                        <img src="../assets/images/logo/test1.png" alt="">
                        <h3>白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖</h3>
                        <p>2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……</p>
                        <span>2021.04.04</span>
                    </div>
                    <div @click="toDetial">
                        <img src="../assets/images/logo/test2.png" alt="">
                        <h3>白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖</h3>
                        <p>2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……</p>
                        <span>2021.04.04</span>
                    </div>
                    <div @click="toDetial">
                        <img src="../assets/images/logo/test3.png" alt="">
                        <h3>白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖</h3>
                        <p>2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……</p>
                        <span>2021.04.04</span>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
        <!-- 媒体报道 -->
        <div class="media-mainc" id="mediaReports">
            <div class="main-left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="main-center">
                <div class="center-top">
                    <div class="center-left">
                        <h3>媒体报道</h3>
                        <img
                            src="../assets/images/logo/组110@2x.png"
                            alt=""
                            class="img-circle"
                        />
                        <h4>Media reports</h4>
                    </div>
                </div>
                <!-- 媒体报道 -->
                <div class="center-bottom">
                    <div class="cb-left">
                        <img :src="
                        require('../assets/images/logo/' + nowReport.src+'.png')" alt="">
                    </div>
                    <div class="cb-right">
                        <div class="cb-top" @click="toDetial">
                            <span >{{ nowReport.date }}</span>
                            <h3>{{ nowReport.title }}</h3>
                            <p>
                                {{ nowReport.content }}
                            </p>
                        </div>
                        <div class="cb-bottom">
                            <pagenation :total="total" @pagechange='pagechange'></pagenation>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
        <!-- 媒体资料库 -->
        <span id="mediaData"></span>
        <div class="media-maind">
            <div class="main-left">
                <img src="../assets/images/logo/标题装饰@2x.png" alt="" />
            </div>
            <div class="main-center">
                <div class="center-top">
                    <div class="center-left">
                        <h3>媒体资料库</h3>
                        <img
                            src="../assets/images/logo/组110@2x.png"
                            alt=""
                            class="img-circle"
                        />
                        <h4>Media Data</h4>
                    </div>
                </div>
                <!-- 媒体资料库 -->
                <div class="center-bottom">
                    <div>
                        <img src="../assets/images/logo/logo1.png" alt="" class="logo-img">
                        <p>美赢集团LOGO</p>
                        <button><span>下载</span><a-icon type="vertical-align-bottom" /></button>
                    </div>
                    <div>
                        <img src="../assets/images/logo/logo2.png" alt="" class="logo-img">
                        <p>酒星科技LOGO</p>
                        <button><span>下载</span><a-icon type="vertical-align-bottom" /></button>
                    </div>
                    <div>
                        <img src="../assets/images/logo/logo1.png" alt="" class="logo-img">
                        <p>美赢集团LOGO</p>
                        <button><span>下载</span><a-icon type="vertical-align-bottom" /></button>
                    </div>
                    <div>
                        <img src="../assets/images/logo/logo2.png" alt="" class="logo-img">
                        <p>酒星科技LOGO</p>
                        <button><span>下载</span><a-icon type="vertical-align-bottom"/></button>
                    </div>
                </div>
            </div>
            <div class="main-right">
                <img src="../assets/images/logo/科技线@2x.png" alt="" />
            </div>
        </div>
    
    
    </div>
    <router-view></router-view>
</div>
</template>
<script>
import anchor from '../components/anchor/anchor.vue'
import pagenation from '../components/pagenation/pagenation.vue'
export default {
    name: 'serviceProduct',
    components: {
        anchor,
        pagenation
    },
    computed: {
        nowRoute() {
            return this.$route.name
        }
    },
    data() {
        return {
            list: [
                {
                    title:'酒星动态',
                    href:'#jiusinDynamic'
                },
                {
                    title:'媒体报道',
                    href:'#mediaReports'
                },
                {
                    title:'媒体资料库',
                    href:'#mediaData'
                },
            ],
            nowReport:{
                    src:'',
                    date:'',
                    title:'',
                    content:''
            },
            reportList:[
                {
                    src:'jytjjukuy@2x',
                    date:'2021.04.04',
                    title:'白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖 — 腾讯网报导',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业“晴雨表”之称的行业盛会，吸引来数千家企业、数万种商品参展。4月4日—6日则是各大酒企品……'
                },
                {
                    src:'图片1@2x',
                    date:'2021.04.04',
                    title:'白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖 — 腾讯网报导',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业“晴雨表”之称的行业盛会，吸引来数千家企业、数万种商品参展。4月4日—6日则是各大酒企品……'
                },
                {
                    src:'image34@2x',
                    date:'2021.04.04',
                    title:'白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖 — 腾讯网报导',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业“晴雨表”之称的行业盛会，吸引来数千家企业、数万种商品参展。4月4日—6日则是各大酒企品……'
                }
            ],
            total: 3
        }
    },
    created() {
        this.nowReport = this.reportList[0]
    },
    methods: {
        toDetial(){
            this.$router.push({path:'/mediaCenter/mediaCenterDetail'})
        },
        pagechange(e) {
            this.nowReport = this.reportList[e-1]
            // console.log(e)
        },
        jumpTo(){
            this.$router.push('./mediaCenter/mediaCenterDetail')
        }
    }
}
</script>
<style lang="scss" scoped>
%h3-common {
    font-size: 46px;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #00227d;
    letter-spacing: 3px;
    padding: 0;
    margin: 0;
}
%h4-common {
    padding: 0;
    margin: 0 0 50px 0;
    text-transform: uppercase;
    color: #2b4079;
    opacity: 0.2;
    letter-spacing: 3px;
    font-family: HarmonyOS Sans SC;
    font-size: 26px;
    font-weight: 900;
}
.img-circle {
    width: 28px;
    height: 13px;
    margin: 20px 0;
}
.mediaCenter {
    .media-head {
        margin-top: 100px;
        width: 100%;
        height: 400px;
        padding-left: calc((100% - 1280px) / 2);
        letter-spacing: 8px;
        box-sizing: border-box;
        background: url('../assets/images/logo/head1.png');
        background-size: 100% 400px;
        background-repeat: no-repeat;
        font-size: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
            color: #fff;
            font-weight: bold;
        }
        h4{
            color: #fff;
            font-size: 30px;
            font-weight: bold;
        }
    }
    .media-maina {
        width: 100%;
        display: flex;
        justify-content: center;
        margin:120px 0 140px 0;
        .main-left {
            width: calc((100% - 1280px) / 2);
            img {
                width: 100%;
            }
            margin-top: 26px;
        }
        .main-center {
            width: 1280px;
            display: flex;
            flex-direction: column;
            .center-top{
                h3 {
                    @extend %h3-common;
                }
                h4 {
                    @extend %h4-common;
                }
            }
            .center-bottom{
                width: 100%;
                display: flex;
                justify-content: space-between;
                letter-spacing: 2px;
                div{
                    cursor: pointer;
                    width: 408px;
                    img{
                        width: 100%;
                        height: 256px;
                        border-radius: 4px;
                    }
                    h3{
                        font-size: 24px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: bold;
                        line-height: 36px;
                        color: #00227D;
                        margin-top: 28px;
                    }
                    p{
                        font-size: 14px;
                        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
                        font-weight: 400;
                        line-height: 28px;
                        color: #111A37;
                    }
                    span{
                        font-size: 16px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 36px;
                        color: #A9B3CC;
                    }
                }
            }
        }
        .main-right {
            width: calc((100% - 1280px) / 2);
            position: relative;
            img {
                width: 100%;
                position: absolute;
                bottom: 250px;
            }
        }
    }
    .media-mainc {
        width: 100%;
        padding: 140px 0;
        // height: 1059px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../assets/images/logo/灰bj@2x.png');
        box-sizing: border-box;
        .main-left {
            width: calc((100% - 1280px) / 2);
            img {
                width: 100%;
            }
            margin-top: -528px;
        }
        .main-center {
            width: 1280px;
            display: flex;
            flex-direction: column;
            .center-top{
                h3 {
                    @extend %h3-common;
                }
                h4 {
                    @extend %h4-common;
                }
            }
            .center-bottom{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: space-between;
                .cb-left{
                    width: 55%;
                    height: 400px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .cb-right{
                    width: 45%;
                    .cb-top{
                        padding: 40px 60px;
                        height: 340px;
                        background: #FFFFFF;
                    }
                    .cb-bottom{
                        // background: brown;
                        padding-left: 20px;
                        margin-top: 46px;
                    }
                    span{
                        font-size: 16px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 36px;
                        color: #A9B3CC;
                        letter-spacing: 1px;
                    }
                    h3{
                        margin: 20px 0 30px 0;
                        font-size: 24px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: bold;
                        line-height: 36px;
                        color: #00227D;
                        letter-spacing: 2px;
                    }
                    p{
                        font-size: 14px;
                        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
                        font-weight: 400;
                        line-height: 28px;
                        color: #111A37;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .main-right {
            width: calc((100% - 1280px) / 2);
            position: relative;
            img {
                width: 100%;
                position: absolute;
                top: 175px;
                // bottom: 0px;
            }
        }
    }
    .media-maind {
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        margin: 140px 0;
        .main-left {
            width: calc((100% - 1280px) / 2);
            img {
                width: 100%;
            }
            margin-top: 26px;
        }
        .main-center {
            width: 1280px;
            display: flex;
            flex-direction: column;
            .center-top{
                h3 {
                    @extend %h3-common;
                }
                h4 {
                    @extend %h4-common;
                }
            }
            .center-bottom{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: space-between;
                div{
                    width: 299px;
                    height: 332px;
                    background: #FFFFFF;
                    border: 2px solid #FFFFFF;
                    box-shadow: 0px 10px 20px rgba(0, 34, 125, 0.06);
                    opacity: 1;
                    border-radius: 4px;
                    img.logo-img{
                        width: 100%;
                        height: 209px;
                        border-radius: 4px;
                    }
                    p{
                        margin-top: 20px;
                        margin-left: 20px;
                        font-size: 18px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #111A37;
                        letter-spacing: 2px;
                    }
                    button{
                        cursor: pointer;
                        text-align: center;
                        width: 65px;
                        height: 30px;
                        margin-left: 20px;
                        border: 1px solid #0037CC;
                        border-radius: 44px;
                        background: transparent;
                        font-size: 12px;
                        font-family: HarmonyOS Sans SC;
                        font-weight: 400;
                        line-height: 16px;
                        color: #0037CC;
                        letter-spacing: 1px;
                        line-height: 30px;
                        span{
                            margin:0 3px;
                        }
                    }
                }
            }
        }
        .main-right {
            width: calc((100% - 1280px) / 2);
            position: relative;
            img {
                width: 100%;
                position: absolute;
                bottom: 45px;
            }
        }
    }
    .maodian {
        border-bottom: 1px solid #f0f2f5;
        height: 69px;
    }
}
</style>